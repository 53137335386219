import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../../components/table";
import { api_address } from "../../Utils/addresses";
import Pagination from "../../components/pagination";

let Home = ({ ...props }) => {
  const [results, setResults] = useState([]);
  const isAuthorized = props.user && !props.user?.expired;
  const token = props.user?.access_token;

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColum, setSortColumn] = useState("points");
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchSearchQuery, setFetchSearchQuery] = useState("");
  const [isDescOrder, setIsDescSortOrder] = useState(true);
 
  const topTenData = [
    {
      headerName: "Place",
      accessor: "place",
    },
    {
      headerName: "Id",
      accessor: "id",
    },
    {
      headerName: "Name",
      accessor: "firstname",
    },
    {
      headerName: "Branch",
      accessor: "branchName",
    },
    // {
    //   headerName: "e-mail",
    //   accessor: "email",
    // },
    {
      headerName: "Points",
      accessor: "points",
      sortColumn: "points",
    },
    {
      headerName: "Correct Answers",
      accessor: "correctAnswersPercentage",
      sortColumn: "correct_ans_rate",
    },
    {
      headerName: "GameDuration",
      accessor: "gameDurationInSeconds",
      sortColumn: "duration",      
    }
  ];

  let fetchData = useCallback(async () => {
    try {
      const response = await fetch(
          `${api_address}/api/scores?count=${0}&pageIndex=${page}&pageSize=${pageSize}&searchTerm=${fetchSearchQuery}&sortColumn=${sortColum}&sortOrder=${sortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
      );

      let paginatedData = await response.json();
      setCount(paginatedData.count);
      setResults(paginatedData.data);
    } catch (error) {
      console.error(error);
    }
  }, [token, page, pageSize, fetchSearchQuery, sortOrder, sortColum]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFetchSearchQuery(searchQuery);

    }, 500);
    return () => clearTimeout(timeoutId);

  }, [searchQuery]);
  
  useEffect(() => {
    fetchData();        
  }, [fetchData]);

  let handleClick = async (page) => {
    setPage(page);
  };

  let changePageSize = async (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  let searchQueryHandler = (searchTerm) => {
    setSearchQuery(searchTerm);
  };
  
  let orderHandler = (column) => {
    setIsDescSortOrder(!isDescOrder);
    if(isDescOrder === true)
    {
      setSortOrder("desc");
    }
    else{
      setSortOrder("asc");  
    }
    
    setSortColumn(column);
  }
  
  return (
    isAuthorized && (
        <div className="bg-gray-200 text-gray-900">
          
          <Table 
              headers={topTenData}
              title="Top 10 scores"
              entryData={results}
              query={searchQuery}
              searchQueryHandler={searchQueryHandler}
              orderHandler={orderHandler}
              currentSortColum={sortColum}
              currentSortOrder={sortOrder}
          />
          {count !== 0 && (
              <Pagination
                  onClickHandler={handleClick}
                  pageSize={pageSize}
                  dataCount={count}
                  pSizeHandler={changePageSize}
                  pageIndex={page}
                  pageChangeHandler={setPage}
              />
          )}
        </div>
    )
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
