import {
  LOAD_SUBSCRIPTIONS_SUCCESS,
  OPEN_MENU,
  CLOSE_MENU,
  REQUEST_LOGIN,
  RECEIVED_LOGIN_SUCCESS,
  RECEIVED_LOGIN_UNAUTHORIZED,
  RECEIVED_LOGIN_ERROR,
  CLOSE_ERROR,
  SET_PLAYER,
  GET_PLAYER,
  NOT_FOUND_PLAYER,
  CLOSE_NOT_FOUND_PLAYER,
  SET_PATH,
  SET_CATEGORIES, SET_ACTIVE_CATEGORY, SET_PLAYER_RESULT, SET_PLAYER_RANKING, REQUEST_PLAYER_RANKING
} from "../Actions";
import {
  SESSION_TERMINATED,
  SILENT_RENEW_ERROR,
  USER_EXPIRED,
  USER_SIGNED_OUT,
  LOAD_USER_ERROR,
} from "redux-oidc";

const initialState = {
  channels: [],
  open: false,
  anchorEl: null,
  player: {
    playerName: null,
    branch: null,
    error: null,
  },
  login: {
    body: {
      // username: '',
      // password: '',
      // returnUrl: ''
    },
    error: null,
    isLoading: false,
  },
  categories: [],
  activeCategory: null,
  playerResult: {
    totalPlayers: 0,
    currentPlace: 0,
    category: "",
    points: 0,
    totalPoints: 0
  },
  playersRanking: {
    data: [],
    isLoading: false,
    error: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_TERMINATED:
    case USER_EXPIRED:
    case USER_SIGNED_OUT:
    case SILENT_RENEW_ERROR:
      return { ...state, channels: [] };
    case LOAD_SUBSCRIPTIONS_SUCCESS:
      return { ...state, channels: action.payload };
    case LOAD_USER_ERROR:
      return { ...state, channels: [] };
    case OPEN_MENU:
      return {
        ...state,
        open: action.payload.open,
        anchorEl: action.payload.anchorEl,
      };
    case CLOSE_MENU:
      return {
        ...state,
        open: action.payload.open,
        anchorEl: action.payload.anchorEl,
      };
    case REQUEST_LOGIN:
      return {
        ...state,
        login: { body: action.payload, isLoading: true, error: null },
      };
    case RECEIVED_LOGIN_SUCCESS:
      return {
        ...state,
        login: { body: action.payload, isLoading: false, error: null },
      };
    case RECEIVED_LOGIN_UNAUTHORIZED:
      return {
        ...state,
        login: {
          body: null,
          isLoading: false,
          error: "Invalid username or password.",
        },
      };
    case RECEIVED_LOGIN_ERROR:
      return {
        ...state,
        login: {
          body: null,
          isLoading: false,
          error: "Failed to connect with server.",
        },
      };
    case CLOSE_ERROR:
      return { ...state, login: { body: null, isLoading: false, error: null } };
    case GET_PLAYER:
      return {
        ...state,
        player: {
          playerName: action.payload.firstName,
          branch: action.payload.branch,
          error: null,
        },
      };
    case SET_PLAYER:
      return {
        ...state,
        player: {
          playerName: action.payload.firstName,
          branch: action.payload.branch,
          error: null,
        },
        router: { location: { path: action.payload.path } },
      };
    case NOT_FOUND_PLAYER:
      return {
        ...state,
        player: { playerName: null, branch: null, error: "Player not found" },
      };
    case CLOSE_NOT_FOUND_PLAYER:
      return {
        ...state,
        player: { playerName: null, branch: null, error: null },
      };
    case SET_PATH:
      return {
        ...state,
        router: { location: { path: action.payload } },
      };
      case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload,
      };
    case SET_PLAYER_RESULT:
      return {
        ...state,
        playerResult: {totalPlayers: action.payload.totalUsers, currentPlace: action.payload.currentPlace, category: action.payload.category, points: action.payload.points, totalPoints: action.payload.totalPoints},
      };
    case REQUEST_PLAYER_RANKING:
      return {
        ...state,
        playersRanking: {data: [], isLoading: true, error: null}
      };
    case SET_PLAYER_RANKING:
      return {
        ...state,
        playersRanking: {data: action.payload.data, count: action.payload.count, isLoading: false, error: null},
      };
    default:
      return state;
  }
}
