import React, { useEffect, useState } from 'react';
import { api_address } from '../../Utils/addresses';

let  Logout = ({props}) => 
{
    const [redirectUri, setRedirectUri] = useState();
    useEffect(() => 
    {
      logMeOut();
    })

    let logMeOut = async () =>  {
        var query = window.location.search;
        var logoutIdQuery = query && query.toLowerCase().indexOf('?logoutid=') === 0 && query;

        const response = await fetch(`${api_address}/api/authenticate/logout` + logoutIdQuery, {
          credentials: 'include',
          method: 'GET',
          headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Accept-Encoding': 'gzip'
          }
        });

        const data = await response.json();

        setRedirectUri(data.postLogoutRedirectUri);
      }  
      let redirect = () => {
        if (redirectUri) {
          window.location = redirectUri;; 
        }
      }
        return(
            <div id="logout_iframe">
                
                <div id="bye">{redirect()}</div>
            </div>
        );
}

export default Logout;