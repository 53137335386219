//import { useHistory } from "react-router-dom";
import { useState } from "react";
import * as types from "../../Actions";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Alert from "@material-ui/lab/Alert";
import { Redirect } from "react-router";

let LoginPage = ({ ...props }) => {
  const dispatch = props.dispatch;
  const playerState = props.player;
  const [player, setPlayer] = useState("");
  const [branch, setBranch] = useState("");
  let handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      playerName: player,
      branch: branch,
    };

    dispatch((dispatch) => {
      dispatch(types.getPlayer(payload));
    });
  };

  let handleChange = (event, setHandler) => {
    const target = event.target;
    const value = target.value;
    //const name = target.name;

    setHandler(value);
  };
  let closeError = () => {
    props.dispatch(types.closeNotFoundError());
  };

  let handleChangeBranch = (event) =>
    handleChange(event, (value) => setBranch(value));

  let handleChangePlayer = (event) =>
    handleChange(event, (value) => setPlayer(value));

  return playerState.playerName !== null ? (
    <Redirect push to="/player" />
  ) : (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <div className="w-full rounded-lg bg-white shadow sm:max-w-md md:mt-0 xl:p-0 dark:border dark:border-gray-700 dark:bg-gray-800">
          <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              noValidate
              onSubmit={handleSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="your@email.com"
                  required=""
                  value={player.playerName}
                  onChange={handleChangePlayer}
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Branch name
                </label>
                <input
                  type="branch"
                  name="branch"
                  id="branch"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="your branch name eg. company name"
                  required=""
                  value={player.branchName}
                  onChange={handleChangeBranch}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 h-4 w-4 rounded border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800"
                      required=""
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-neutral bg-primary-600 hover:bg-primary-700 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4"
              >
                Sign in
              </button>
              {playerState !== undefined && playerState.error !== null && (
                <Alert onClose={closeError} severity="error">
                  {playerState.error}
                </Alert>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

//export default LoginPage;

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    player: state.subscriptions.player,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    push,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
