import React, { useState } from "react";
import Popup from "./popup";
import SearchBar from "./searchBar";

let Table = ({ headers, entryData, deleteHandler, title, searchQuery, searchQueryHandler, orderHandler, currentSortColum, currentSortOrder }) => {
    const [seen, setSeen] = useState(false);
    const [id] = useState(0);

    let togglePopUp = () => setSeen(!seen);

    return (
        <div className="mt-20 sm:px-6 w-full">
            {seen ? <Popup id={id} visibleHandler={togglePopUp} deleteHandler={deleteHandler}/> : null}
            <div className="text-gray-900 pt-20 lg:p-20">
                <div className="p-4 flex">

                    <div
                        className="flex w-full flex-col justify-between space-y-5 p-3 lg:flex-row lg:items-center lg:space-y-0">
                        <h1 className="text-3xl">
                            {title}
                        </h1>
                        <SearchBar query={searchQuery} func={searchQueryHandler}/>
                    </div>
                </div>
                <div className="mt-7 overflow-x-auto">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4 whitespace-nowrap">
                        <tbody>
                        
                            <tr className="border-b" >
                                {headers.map((thName) => {
                                    return thName.sortColumn ?
                                        <th onClick={() => orderHandler(thName.sortColumn)}
                                            className="text-left p-3 px-5">
                                            <div className="flex items-center">
                                                <p className="text-base font-medium leading-none text-gray-700 mr-2">{thName.headerName}</p>
                                                    {thName.sortColumn === currentSortColum && currentSortOrder === "desc" ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"/>
                                                    </svg> :
                                                    thName.sortColumn === currentSortColum && currentSortOrder === "asc" ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"/>
                                                        </svg> : <div></div>
                                                    
                                                }
                                            </div>
                                        </th>
                                :
                                        <th className="text-left p-3 px-5">
                                            <p className="text-base font-medium leading-none text-gray-700 mr-2">{thName.headerName}</p>
                                        </th>
                                })}
                                <th></th>
                            </tr>
                            {entryData.map((data) => {
                                return (
                                    <tr key={data.id} className="border-b hover:bg-orange-100 bg-gray-100">
                                        {headers.map((header) => {
                                            return (<td className="p-3 px-5">
                                                <div className="flex items-center">
                                                    <p className="text-base leading-none text-gray-700 mr-2">{data[header.accessor]}</p>
                                                </div>
                                            </td>
                                        )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Table;