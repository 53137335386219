import { connect } from "react-redux";

let TableHeader = ({setCategoryHandle, ...props}) => {
    const {categories, activeCategory} = props;

    return (
        <div className="sm:flex items-center justify-between">
            <div className="flex items-center">
                {categories.length === 0 ? <p></p> : categories.map((el) => {
                    return (
                        <button key={el} className="rounded-full focus:outline-none focus:ring-2 focus:bg-indigo-50 focus:ring-indigo-800  ml-4 sm:ml-8"
                           onClick={() => setCategoryHandle(el)}>
                            {activeCategory === el ?
                                <div className="py-2 px-8 bg-indigo-100 text-indigo-700 rounded-full">
                                    <p>{el}</p>
                                </div> :
                                <div className="py-2 px-8 text-gray-600 hover:text-indigo-700 hover:bg-indigo-100 rounded-full ">
                                     <p>{el}</p>
                                </div>
                            }
                        </button>)
                })}
            </div>
        </div>


    )
}

function mapStateToProps(state) {
    return {
        activeCategory: state.subscriptions.activeCategory,
        categories: state.subscriptions.categories
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableHeader);