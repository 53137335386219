import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Pagination from "../../components/pagination";
import Table from "./table";
import { api_address } from "../../Utils/addresses";
import WipeData from "../WipeData";
import Popup from "../../components/popup";

let Dashboard = ({ ...props }) => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const token = props.user.access_token;
  const [loading, setLoading] = useState(false);
  const [seen, setSeen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  let fetchData = useCallback(async () => {
    try {
      let response = await fetch(
        `${api_address}/api/users?pageIndex=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Cross-Origin-Embedder-Policy": "require-corp",
            "Cross-Origin-Opener-Policy": "same-origin",
          },
        },
      );

      let json = await response.json();
      setUsers(json.data);
      setCount(json.count);
    } catch (error) {
      console.error(error);
    }
  }, [searchQuery, token, page, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData, pageSize]);

  let togglePopUp = () => setSeen(!seen);

  let handleClick = async (page) => {
    //await fetchData(page, pageSize);
    setPage(page);
  };

  let handleDelete = async (id) => {
    var removed = users.filter((x) => {
      return x.id !== id;
    });
    setUsers(removed);
    await deleteUser(id);
    await fetchData(page, pageSize);
  };

  let deleteUser = async (id) => {
    try {
      await fetch(`${api_address}/api/users/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  let handleWipeDatabase = async () => {
    await wipeDatabase();
    await fetchData(page, pageSize);
  };

  let wipeDatabase = async () => {
    try {
      setLoading(true);
      await fetch(`${api_address}/api/trunc`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  let changePageSize = async (event) => {
    setPageSize(event.target.value);
    setPage(1);
    //await fetchData(1, ps);
  };

  let search = (query) => {
    if (query !== undefined) {
      setSearchQuery(query);
    }
  };

  return (
    <div className={"bg-gray-200"}>
      {seen ? (
        <Popup
          visibleHandler={togglePopUp}
          deleteHandler={handleWipeDatabase}
        />
      ) : null}
      {loading ? (
        <div>Loading....</div>
      ) : (
        <div className="flex w-full flex-col justify-between space-y-5 p-3 lg:flex-row lg:items-end lg:space-y-0">
          <WipeData deleteHandler={() => setSeen(true)} />{" "}
        </div>
      )}
      <Table
        searchQuery={searchQuery}
        searchQueryHandler={search}
        users={users}
        deleteHandler={handleDelete}
      />
      <Pagination
        onClickHandler={handleClick}
        pageSize={pageSize}
        dataCount={count}
        pSizeHandler={changePageSize}
        pageIndex={page}
        pageChangeHandler={setPage}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export default connect(mapStateToProps, null)(Dashboard);
