import TableHeader from "./TableHeader";
import {useEffect, useState} from "react";
import { connect } from "react-redux";
import * as types from '../../Actions';
import Pagination from "../../components/pagination";


let PlayerCategoryDashboard = ({...props}) => {
    const {player, playerResult, playersRanking} = props
    //const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    console.log(props.category)

    useEffect(() => {
        props.dispatch(types.getCategories(player));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCategoryHandler = (category) => {
        props.dispatch(types.playerRankingResultBatch(category, player))
    }
    let changePageSize = async (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };
    let handleClick = async (page) => {
        let category = props.category;
        setPage(page);
        
        const pagedRequest = {category, pageSize, page};
        props.dispatch(types.getPlayerRanking(pagedRequest))
    };
    
    return (
        <div className="mt-20 sm:px-6 w-full">
            

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="flex items-center justify-between">
                        <p className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl mb-10">Player: {player.playerName}</p>
                        <p className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl mb-10">Group: {player.branch}</p>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-gray-600">Current place</dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {playerResult.currentPlace}
                            </dd>
                        </div>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-gray-600">Total players</dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {playerResult.totalPlayers}
                            </dd>
                        </div>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-gray-600">Category</dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {playerResult.category.toUpperCase()}
                            </dd>
                        </div>
                    </dl>
                    <dl className="mt-10 grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-2">
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-gray-600">Points in category</dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {playerResult.points}
                            </dd>
                        </div>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-gray-600">Total points</dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {playerResult.totalPoints}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10">
                <TableHeader setCategoryHandle={setCategoryHandler}/>
                <div className="mt-7 overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                        <tbody>
                        {!playersRanking.isLoading ? playersRanking.data.map((row, idx) => {

                            return <tr key={idx} tabIndex="0" className="focus:outline-none h-16 border border-gray-100 ">
                                <td>
                                    <div className="ml-5">
                                        {idx + 1 + ((page-1) * pageSize)}.
                                    </div>
                                </td>
                                <td className="">
                                    <div className="flex items-center pl-5">
                                        <p className="text-base font-medium leading-none text-gray-700 mr-2">
                                            {row.firstName}
                                        </p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16"
                                             fill="none">
                                            <path
                                                d="M6.66669 9.33342C6.88394 9.55515 7.14325 9.73131 7.42944 9.85156C7.71562 9.97182 8.02293 10.0338 8.33335 10.0338C8.64378 10.0338 8.95108 9.97182 9.23727 9.85156C9.52345 9.73131 9.78277 9.55515 10 9.33342L12.6667 6.66676C13.1087 6.22473 13.357 5.62521 13.357 5.00009C13.357 4.37497 13.1087 3.77545 12.6667 3.33342C12.2247 2.89139 11.6251 2.64307 11 2.64307C10.3749 2.64307 9.77538 2.89139 9.33335 3.33342L9.00002 3.66676"
                                                stroke="#3B82F6" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path
                                                d="M9.33336 6.66665C9.11611 6.44492 8.8568 6.26876 8.57061 6.14851C8.28442 6.02825 7.97712 5.96631 7.66669 5.96631C7.35627 5.96631 7.04897 6.02825 6.76278 6.14851C6.47659 6.26876 6.21728 6.44492 6.00003 6.66665L3.33336 9.33332C2.89133 9.77534 2.64301 10.3749 2.64301 11C2.64301 11.6251 2.89133 12.2246 3.33336 12.6666C3.77539 13.1087 4.37491 13.357 5.00003 13.357C5.62515 13.357 6.22467 13.1087 6.66669 12.6666L7.00003 12.3333"
                                                stroke="#3B82F6" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                </td>
                                <td className="pl-24">
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"/>
                                        </svg>

                                        <p className="text-sm leading-none text-gray-600 ml-2">
                                            {row.branchName}
                                        </p>
                                    </div>
                                </td>
                                <td className="pl-5">
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>

                                        <p className="text-sm leading-none text-gray-600 ml-2">{row.gameDuration}</p>
                                    </div>
                                </td>
                                <td className="pl-5">
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"/>
                                        </svg>
                                        <div  className="tooltip" data-tip={((row.totalCorrectAnswersRate ?? 0)).toFixed(2)+"%"}>  
                                            <progress className="progress progress-accent w-56" value={((row.totalCorrectAnswersRate ?? 0).toFixed(2))}
                                                      max={100}></progress>
                                        </div>

                                    </div>
                                </td>
                                <td className="pl-5">
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"/>
                                        </svg>
                                        <p className="text-sm leading-none text-gray-600 ml-2">
                                            {row.bestScore}
                                        </p>
                                    </div>
                                </td>
                            </tr>

                        }) : (<tr className="focus:outline-none h-16 border border-gray-100" >
                            <td className="pl-10">
                                <div className="flex items-center">
                                    Loading
                                </div>
                            </td>
                        </tr>)}

                        </tbody>
                    </table>

                    <Pagination
                        onClickHandler={handleClick}
                        pageSize={pageSize}
                        dataCount={playersRanking.count}
                        pSizeHandler={changePageSize}
                        pageIndex={page}
                        pageChangeHandler={setPage}
                    />
                </div>
            </div>
        </div>)
}


function mapStateToProps(state) {
    return {
        player: state.subscriptions.player,
        category: state.subscriptions.activeCategory,
        playerResult: state.subscriptions.playerResult,
        playersRanking: state.subscriptions.playersRanking,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerCategoryDashboard);