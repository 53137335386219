import React from 'react';

function Root(props) {
  return (
      <div >
        { props.children }
      </div>
  );
}


export default Root;