import { Redirect, Route } from "react-router";
import { push } from "connected-react-router";
import { connect } from "react-redux";

let PlayerReadOnlyRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return rest.player != null &&
          rest.player.playerName != null &&
          rest.player.branch != null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

function mapStateToProps(state) {
  const player = state.subscriptions.player;
  return {
    player,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    push,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerReadOnlyRoute);
