//const api_address = 'https://szkoleniowagra.pl/admin';
//const api_address = 'https://localhost:5001';
const api_address = 'https://score.lge-gaming.com';
//const api_address = "http://server323255.nazwa.pl";

//const client_address = 'https://szkoleniowagra.pl/admin';
//const client_address = 'http://localhost:5000/admin';
const client_address = 'https://score.lge-gaming.com';
//const client_address = 'http://localhost:3000';
//const client_address = "http://server323255.nazwa.pl";

export { api_address, client_address };
