import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React, { useState } from "react";
import Popup from "../../components/popup";
import SearchBar from "../../components/searchBar";

let Table = ({ users, deleteHandler, searchQuery, searchQueryHandler }) => {
  const [seen, setSeen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [player, setPlayer] = useState({});

  let togglePopUp = () => setSeen(!seen);
  return (
    <div>
      {seen ? (
        <Popup
          id={userId}
          name={player.firstName}
          visibleHandler={togglePopUp}
          deleteHandler={deleteHandler}
        />
      ) : null}
      <div className="lg:p-10 text-gray-900">
        <div className="flex w-full flex-col justify-between space-y-5 p-3 lg:flex-row lg:items-center lg:space-y-0">
          <h1 className="text-3xl">Users</h1>

          <SearchBar query={searchQuery} func={searchQueryHandler} />
        </div>
        <div className="mt-7 overflow-x-auto">
          <table className="text-md mb-4 w-full rounded bg-white shadow-md">
            <tbody>
              <tr className="border-b">
                <th className="p-3 px-5 text-left">Id</th>
                <th className="p-3 px-5 text-left">Name</th>
                <th className="p-3 px-5 text-left">Branch</th>
                {/*<th className="p-3 px-5 text-left">Email</th>*/}
                <th className="p-3 px-5 text-left">Best Score</th>
                <th></th>
              </tr>
              {users.lenght === 0 ? (
                <td colSpan={5}>no data</td>
              ) : (
                users.map((user) => {
                  return (
                    <tr
                      key={user.id}
                      className="border-b bg-gray-100 hover:bg-orange-100"
                    >
                      <td className="p-5 text-left">
                        <p>{user.id}</p>
                      </td>
                      <td className="p-3 px-5">
                        <p>
                          <input
                            type="text"
                            readOnly
                            defaultValue={user.firstname}
                            className="bg-transparent"
                          />
                        </p>
                      </td>
                      <td className="p-3 px-5">
                      <input
                          type="text"
                          readOnly
                          defaultValue={user.branchName}
                          className="bg-transparent"
                        />
                      </td>
                      <td className="p-5 text-left">
                        <div className="text-sm font-semibold tracking-wide text-gray-500">
                          <p>Best score: {user.points}pts</p>
                          <p>Total time from best scores: {user.gameDurationInSeconds}</p>
                          <p>Total correct answers: {user.correctAnswersPercentage}%</p>
                        </div>
                      </td>
                      <td className="flex justify-end p-3 px-5">
                        <Link
                          component={RouterLink}
                          to={{
                            pathname: `/admin/score/${user.id}`,
                            userId: user.id,
                          }}
                          type="button"
                          className="page-link focus:shadow-outline mr-3 rounded bg-blue-500 px-2 py-1 text-white visited:text-purple-600 hover:bg-white hover:text-blue-800 focus:outline-none"
                        >
                          Scores
                        </Link>
                        <button
                          type="button"
                          onClick={() => {
                            setSeen(!seen);
                            setUserId(user.id);
                            setPlayer(user);
                          }}
                          className="focus:shadow-outline rounded bg-red-500 px-2 py-1 text-sm text-white hover:bg-red-700 focus:outline-none"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
