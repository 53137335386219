import React from "react";

let Popup = ({ id, visibleHandler, deleteHandler, name }) => {
  return (
    <div className="animated fadeIn bg-smoke-dark fixed inset-0 z-50 flex overflow-auto object-center">
      <div className="animated fadeInUp pin-b pin-x fixed m-auto flex w-full max-w-md flex-col justify-end bg-white p-8 align-top shadow-inner md:relative md:h-auto md:justify-center md:rounded md:shadow">
        <h2 className="font-hairline text-grey mb-4 text-center text-4xl md:mt-8 md:leading-loose">
          Question!
        </h2>
        <p className="mb-8 text-center text-xl leading-normal">
          Are you sure to delete {name}?
        </p>
        <div className="inline-flex justify-center">
          <button
            onClick={() => {
              deleteHandler(id);
              visibleHandler();
            }}
            className="bg-grey-lighter border-green hover:bg-green-lightest text-grey-darkest ml-2 flex-1 rounded border-b-2 px-6 py-4 font-bold md:flex-none"
          >
            Absolutely
          </button>
          <button
            onClick={visibleHandler}
            className="bg-grey-lighter border-red hover:bg-red-lightest text-grey-darkest ml-2 flex-1 rounded border-b-2 px-6 py-4 font-bold md:flex-none"
          >
            Not so much
          </button>
        </div>
        <span
          onClick={visibleHandler}
          className="pin-t pin-r absolute right-0 top-0"
        >
          <svg
            className="text-grey hover:text-grey-darkest h-12 w-12"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default Popup;
