import React from "react";
import { ConnectedRouter } from "connected-react-router/immutable";
import { Route } from "react-router";
import Home from "../Pages/Home";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import { history } from "../store";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import CustomCallback from "../Pages/Callback";
import Logout from "../Pages/Logout";
import Header from "../Layout/Header";
import { SilentCallback } from "../Pages/Callback/SilentCallback";
import Score from "../Pages/Score";
import CategoryDashBoard from "../Pages/CategoryDashboard";
import LoginPage from "../Pages/PlayerDashboard";
import PlayerCategoryDashboard from "../Pages/PlayerDashboard/PlayerCategoryTable";
import PlayerReadOnlyRoute from "./PlayerReadOnlyRoute";

// eslint-disable-next-line no-unused-vars
const PageNotFound = () => <div>Page not found</div>;

const PreLogoutPage = () => <div>logging out!</div>;

const Routes = () => (
  <ConnectedRouter history={history}>
    <div>
      <PrivateRoute exact path="/admin">
        {" "}
        <Header>
          <Home />
        </Header>{" "}
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard">
        <Header>
          <Dashboard />
        </Header>
      </PrivateRoute>
      <PrivateRoute path="/admin/score/:userId">
        <Header>
          <Score />
        </Header>
      </PrivateRoute>
      <PrivateRoute path="/admin/category">
        <Header>
          <CategoryDashBoard />
        </Header>
      </PrivateRoute>
      <PublicRoute path="/admin/signin">
        <Header>
          <Login />
        </Header>
      </PublicRoute>
      <Route path="/admin/callback" component={CustomCallback} />
      <Route path="/admin/silent" component={SilentCallback} />
      <Route path="/admin/logout" component={Logout} />
      <Route path="/admin/prelogout" component={PreLogoutPage} />
      <Route exact path="/" component={LoginPage} />
      <PlayerReadOnlyRoute path="/player" >
        <PlayerCategoryDashboard />
      </PlayerReadOnlyRoute>
      {/*<Route path="/player" component={PlayerCategoryDashboard} />*/}
    </div>
  </ConnectedRouter>
);

export default Routes;
