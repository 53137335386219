import { Link } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { api_address } from "../../Utils/addresses";
import ScoreTable from "./ScoreTable";
let Score = ({ ...props }) => {
  const [pageSize] = useState(3);
  const [page] = useState(1);
  let { userId } = useParams();
  const [scores, setScores] = useState([]);
  const token = props.user.access_token;
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleDelete = async (id) => {
    var removed = scores.filter((x) => {
      return x.id !== id;
    });
    setScores(removed);
    await deleteScore(id);
    await fetchData(page, pageSize);
  };

  let fetchData = async () => {
    try {
      let response = await fetch(
        `${api_address}/api/users/${userId}/scores?pageIndex=1&pageSize=100`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      );
      var data = await response.json();
      setScores(data);
    } catch (error) {
      console.log(error);
    }
  };

  let deleteScore = async (id) => {
    try {
      await fetch(`${api_address}/api/scores/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-gray-200 lg:px-20 text-gray-900">
      <div>
        <Link className="p-20" component={RouterLink} to="/admin/dashboard">
          {"< Go Back"}
        </Link>
      </div>
      <ScoreTable
        playerId={userId}
        scores={scores}
        deleteHandler={handleDelete}
      />
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.oidc.user,
  };
};

export default connect(mapStateToProps, null)(Score);
