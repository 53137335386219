import axios from "axios";
import { api_address } from "../Utils/addresses";

export const LOAD_SUBSCRIPTIONS_START = "scoring-app/LOAD_SUBSCRIPTIONS_START";
export const LOAD_SUBSCRIPTIONS_SUCCESS =
  "scoring-app/LOAD_SUBSCRIPTIONS_SUCCESS";
export const LOAD_SUBSCRIPTIONS_ERROR = "scoring-app/LOAD_SUBSCRIPTIONS_ERROR";
export const OPEN_MENU = "scoring-app/OPEN_MENU";
export const CLOSE_MENU = "scoring-app/CLOSE_MENU";
export const REQUEST_LOGIN = "scoring-app/REQUEST_LOGIN";
export const RECEIVED_LOGIN_SUCCESS = "scoring-app/RECEIVED_LOGIN_SUCCESS";
export const RECEIVED_LOGIN_UNAUTHORIZED =
  "scoring-app/RECEIVED_LOGIN_UNAUTHORIZED";
export const RECEIVED_LOGIN_ERROR = "scoring-app/RECEIVED_LOGIN_ERROR";
export const CLOSE_ERROR = "scoring-app/CLOSE_ERROR";
export const LOAD_PLAYER_START = "scoring-app/LOAD_PLAYER_START";
export const LOAD_PLAYER_SUCCESS = "scoring-app/LOAD_PLAYER_SUCCESS";
export const LOAD_PLAYER_FAILED = "scoring-app/LOAD_PLAYER_FAILED";

export const SET_PLAYER = "SET_PLAYER";
export const GET_PLAYER = "GET_PLAYER";
export const NOT_FOUND_PLAYER = "NOT_FOUND_PLAYER";
export const CLOSE_NOT_FOUND_PLAYER = "CLOSE_NOT_FOUND_PLAYER";
export const SET_PATH = "SET_PATH";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
export const SET_PLAYER_RESULT = "SET_PLAYER_RESULT";
export const SET_PLAYER_RANKING = "SET_PLAYER_RANKING";
export const SET_PLAYER_RESULT_ERROR = "SET_PLAYER_RESULT_ERROR";
export const REQUEST_PLAYER_RANKING = "REQUEST_PLAYER_RANKING";
export function loadSubscriptionsStart() {
  return {
    type: LOAD_SUBSCRIPTIONS_START,
  };
}

export function loadSubscriptionsSuccess(channels) {
  return {
    type: LOAD_SUBSCRIPTIONS_SUCCESS,
    payload: channels,
  };
}

export function loadSubscriptionsError() {
  return {
    type: LOAD_SUBSCRIPTIONS_ERROR,
  };
}

export function openMenu(open) {
  return {
    type: OPEN_MENU,
    payload: open,
  };
}

export function closeMenu(open) {
  return {
    type: CLOSE_MENU,
    payload: open,
  };
}

export function closeError() {
  return {
    type: CLOSE_ERROR,
    payload: null,
  };
}

export function loadPlayer(playerId) {
  return async (dispatch) => {
    dispatch({ type: LOAD_PLAYER_START, payload: playerId });
  };
}

export function requestLogin(loginData) {
  return async (dispatch) => {
    dispatch({ type: REQUEST_LOGIN, payload: loginData });

    let axiosConfig = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Cross-Origin-Embedder-Policy": "require-corp",
        "Cross-Origin-Opener-Policy": "same-origin",
      },
    };
    let response = {};

    const axiosInstance = axios.create(axiosConfig);
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const { status } = error.response;
        if (status === 401) {
          dispatch({ type: RECEIVED_LOGIN_UNAUTHORIZED, payload: error });
        } else {
          dispatch({ type: RECEIVED_LOGIN_ERROR, payload: error });
        }
        return Promise.reject(error);
      },
    );
    try {
      response = await axiosInstance.post(
        `${api_address}/api/authenticate`,
        loginData,
      );
      dispatch(receivedLogin(response));
    } catch (error) {
      console.error(error);
    }
  };
}

export function receivedLogin(response) {
  window.location = response.data.redirectUrl;
  return {
    type: RECEIVED_LOGIN_SUCCESS,
    payload: response,
  };
}

export function getPlayer(player) {
  return async (dispatch) => {
    let response = await fetch(
      `${api_address}/api/players?firstName=${player.playerName}&groupName=${player.branch}`,
      {
        method: "GET",
        headers: {
          // Authorization: "Bearer " + token,
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
        },
      },
    );

    if (response.status === 404) {
      dispatch({ type: NOT_FOUND_PLAYER, payload: player });
      return;
    }

    let json = await response.json();
    dispatch(setPlayer(json));
  };
}

export function setPlayer(player) {
  return (dispatch) => {
    const payload = { path: "/player", ...player };
    dispatch({ type: SET_PLAYER, payload });
  };
}

export function closeNotFoundError() {
  return {
    type: CLOSE_NOT_FOUND_PLAYER,
    payload: null,
  };
}


export let getCategories = (player) => {
  return async (dispatch) => {
      try {
          let response = await fetch(
              `${api_address}/api/categories`,
              {
                  headers: {
                      "Cross-Origin-Embedder-Policy": "require-corp",
                      "Cross-Origin-Opener-Policy": "same-origin",
                  },
              },
          );

          let json = await response.json();
          dispatch(setCategories(json.data))
          dispatch(playerRankingResultBatch(json.data[0], player));
      } catch (error) {
          console.error(error);
      }
  }
}

export let playerRankingResultBatch = (category, player) => {
    const pagedRequest = {pageSize: 10, page: 1};
    return dispatch => {
        return Promise.all([dispatch(setActiveCategory(category)),
        dispatch(getUserResults({category: category, ...player})),
        dispatch(getPlayerRanking({category, ...pagedRequest}))]);    
    }
}

export let setCategories = (payload) => {
  return {
    type: SET_CATEGORIES,
    payload: payload
  }
}

export let setActiveCategory = (payload) => {
  return {
    type: SET_ACTIVE_CATEGORY,
    payload: payload
  }
}

export let getUserResults = (payload) => {
  return async (dispatch) => {
    let response = await fetch(`${api_address}/api/users/categories/${payload.category}?firstName=${payload.playerName}&branchName=${payload.branch}`, {
      headers: {
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
      },
  });

    if(response.status !== 200)
    {
        dispatch({type: SET_PLAYER_RESULT_ERROR, payload: response.json() })
        return;
    }
    
  let data = await response.json();
  
  dispatch(setPlayerResult(data));
  }
}

export let setPlayerResult = (payload) => {
    return {
        type: SET_PLAYER_RESULT,
        payload: payload,
    }
}

export let getPlayerRanking = (payload) => {
    const {category, pageSize, page} = payload;
    return async (dispatch) => {
        dispatch({type: REQUEST_PLAYER_RANKING, payload: payload})
        let response = await fetch(`${api_address}/api/users/categories/${category}/ranking?pageSize=${pageSize}&pageIndex=${page}`, {
            headers: {
                "Cross-Origin-Embedder-Policy": "require-corp",
                "Cross-Origin-Opener-Policy": "same-origin",
            },
        });

        let data = await response.json();
        dispatch({
            type: SET_PLAYER_RANKING,
            payload: data
        });
    }
}