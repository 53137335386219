import React, { useEffect, useState } from "react";
import Popup from "../../components/popup";
import { api_address } from "../../Utils/addresses";
import { connect } from "react-redux";

let ScoreTable = ({ playerId, scores, deleteHandler, ...props }) => {
  const [seen, setSeen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [player, setPlayer] = useState({});
  useEffect(() => {
    fetchPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const token = props.user.access_token;

  let togglePopUp = () => setSeen(!seen);
  let fetchPlayer = async () => {
    try {
      let response = await fetch(`${api_address}/api/users/${playerId}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      let data = await response.json();
      setPlayer(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {seen ? (
        <Popup
          id={userId}
          visibleHandler={togglePopUp}
          deleteHandler={deleteHandler}
        />
      ) : null}
      <div className="pt-20 lg:p-20 text-gray-900 w-full">
        <div className="flex p-4">
          <h1 className="text-3xl">User {player.firstname} {player.branchName}</h1>
        </div>
        <div className="mt-7 overflow-x-auto">
          <table className="text-md mb-4 w-full rounded bg-white shadow-md">
            <tbody>
              <tr className="border-b">
                <th className="p-3 px-5 text-left">Id</th>
                <th className="p-3 px-5 text-left">Points</th>
                <th className="p-3 px-5 text-left">Game Duration</th>
                <th className="p-3 px-5 text-left">Created Time</th>
                <th className="p-3 px-5 text-left">Game Category</th>
                <th className="p-3 px-5 text-left">Correct Answers %</th>
                <th className="p-3 px-5 text-left">Action</th>
                <th></th>
              </tr>
              {scores.map((score) => {
                return (
                  <tr
                    key={score.id}
                    className="border-b bg-gray-100 hover:bg-orange-100"
                  >
                    <td className="p-5 text-left">
                      <p>{score.id}</p>
                    </td>
                    <td className="p-3 px-5">
                      <input
                        type="text"
                        readOnly
                        defaultValue={score.points}
                        className="bg-transparent"
                      />
                    </td>
                    <td className="p-3 px-5">
                      <input
                        type="text"
                        readOnly
                        defaultValue={score.gameDurationInSeconds}
                        className="bg-transparent"
                      />
                    </td>
                    <td className="p-3 px-5">{score.createdOn}</td>
                    <td className="p-3 px-5">{score.category}</td>
                    <td className="p-3 px-5">{score.correctAnswersPercentage}</td>
                    <td className="flex justify-end p-3 px-5">
                      <button
                        type="button"
                        onClick={() => {
                          setSeen(!seen);
                          setUserId(score.id);
                        }}
                        className="focus:shadow-outline rounded bg-red-500 px-2 py-1 text-sm text-white hover:bg-red-700 focus:outline-none"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

//export default ScoreTable;

let mapStateToProps = (state) => {
  return {
    user: state.oidc.user,
  };
};

export default connect(mapStateToProps, null)(ScoreTable);
