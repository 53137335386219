import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
//import Pagination from "../../components/pagination";
import Table from "../../components/table";
import { api_address } from "../../Utils/addresses";

let CategoryDashboard = ({ ...props }) => {
  const token = props.user.access_token;
  const [scoresByCategory, setScoresByCategory] = useState();
  //const [searchQuery, setSearchQuery] = useState("");
  // const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  const [pageSize] = useState(3);

  let fetchData = useCallback(async () => {
    try {
      let response = await fetch(`${api_address}/api/users/scores/categories`, {
        headers: {
          Authorization: "Bearer " + token,
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
        },
      });

      let json = await response.json();
      console.log(json);
      setScoresByCategory(json);
      //setCount(json.count);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, pageSize]);

  // let handleClick = async (page) => {
  //   //await fetchData(page, pageSize);
  //   setPage(page);
  // };
  // let changePageSize = async (event) => {
  //   setPageSize(event.target.value);
  //   setPage(1);
  //   //await fetchData(1, ps);
  // };

  const categoryHeaders = [
    {
      headerName: "Place",
      accessor: "place",
    },
    {
      headerName: "Id",
      accessor: "id",
    },
    {
      headerName: "Name",
      accessor: "firstname",
    },
    {
      headerName: "Branch",
      accessor: "branchName",
    },
    {
      headerName: "Points",
      accessor: "points",
    },
    {
      headerName: "Game duration",
      accessor: "gameDurationInSeconds",
    },
  ];

  return (
    <div className={"bg-gray-200"}>
      {scoresByCategory
        ? Object.keys(scoresByCategory).map((key, index) => (
            <Table
              headers={categoryHeaders}
              title={key}
              entryData={scoresByCategory[key]}
            />
          ))
        : "Loading"}

      {/*<Pagination*/}
      {/*  onClickHandler={handleClick}*/}
      {/*  pageSize={pageSize}*/}
      {/*  dataCount={count}*/}
      {/*  pSizeHandler={changePageSize}*/}
      {/*  pageIndex={page}*/}
      {/*  pageChangeHandler={setPage}*/}
      {/*/>*/}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export default connect(mapStateToProps, null)(CategoryDashboard);
